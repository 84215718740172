@font-face {
  font-family: 'korolev';
  src: url('https://use.typekit.net/af/ade006/00000000000000007735e8d0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
    format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'korolev';
  src: url('https://use.typekit.net/af/75d7a9/00000000000000007735e8da/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'korolev';
  src: url('https://use.typekit.net/af/83f011/00000000000000007735e8d8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'korolev';
  src: url('https://use.typekit.net/af/76f2a5/00000000000000007735e8cc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
    format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'korolev';
  src: url('https://use.typekit.net/af/4fbb2b/00000000000000007735e8e7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'korolev';
  src: url('https://use.typekit.net/af/563522/00000000000000007735e8b2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
    format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
  font-weight: 700;
  font-style: normal;
}

.tk-korolev-compressed {
  font-family: 'korolev-compressed', sans-serif;
}
.tk-korolev {
  font-family: 'korolev', sans-serif;
}
.tk-korolev-condensed {
  font-family: 'korolev-condensed', sans-serif;
}
.tk-korolev-military-stencil {
  font-family: 'korolev-military-stencil', sans-serif;
}
.tk-korolev-rounded {
  font-family: 'korolev-rounded', sans-serif;
}
