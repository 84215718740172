@import 'styles/core/typography';

.loading {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .spinner {
    width: 6rem;
    height: 6rem;

    @media screen and (min-width: $breakpoint-md) {
      width: 10rem;
      height: 10rem;
    }
  }
}
