.target {
  box-shadow: 1px 1px var(--color-accent-opaque);
}

.container {
  &[class*='hidden'] {
    height: 150;
    bottom: -300px;
    opacity: 0;
    z-index: -1;
  }

  & [class*='simple-keyboard'] {
    & [class*='hg-row'] {
      & [class*='hg-button'] {
        &[class*='hg-button-shiftright-'] {
          max-width: 90px;
        }
        color: var(--color-on-bg-primary);
        border: none;
        font-size: 18px;
        min-width: 40px;
        min-height: 50px;
        box-shadow: 1px 1px var(--color-accent-opaque);
        font-family: var(--font-family-sans);
        background-color: var(--color-surface-02);

        &:active,
        &[class*='selectedButton'] {
          color: var(--color-accent);
          background-color: var(--color-surface-03);
        }

        &[class*='emptySpace'] {
          border: none;
          background: none;
          box-shadow: none;
          pointer-events: none;
        }
      }

      align-items: center;
    }
  }

  legend {
    gap: 10px;
    width: 78%;
    display: flex;
    font-size: 16px;
    min-width: 640px;
    white-space: noWrap;
    font-family: var(--font-family-sans);
    align-items: flex-end;
    padding-left: 4px;

    label {
      color: var(--color-accent);
      opacity: 0.7;
    }

    div {
      color: var(--color-secondary);
      border: 0.1rem solid var(--color-divider-primary);
      height: 32px;
      opacity: 0.9;
      padding: var(--space-size-1);
      flex-grow: 1;
      font-size: 18px;
      min-width: 200px;
      text-align: center;
      border-radius: var(--space-size-0-5);
      background-color: var(--color-surface-01);
    }
  }

  gap: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 90%;
  height: 330px;
  margin: 0 50%;
  bottom: 0;
  opacity: 1;
  padding: var(--space-size-2);
  padding-bottom: 0;
  z-index: 99999999999;
  position: fixed;
  min-width: 1024px;
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px var(--color-divider-primary);
  border: 1px solid var(--color-divider-primary);
  transition: all 500ms;
  border-radius: 0;
  background-color: var(--color-bg);

  border-top-left-radius: var(--space-size-1);
  border-top-right-radius: var(--space-size-1);
}

.keyboard {
  &Main {
    &[class*='simple-keyboard'] {
      & [class*='hg-rows'] {
        & [class*='hg-row'] {
          &:nth-child(3) {
            margin-left: 50px !important;
          }

          &:nth-child(5) {
            & [class*='hg-standardBtn'] {
              flex-basis: 8%;
            }
            & [class*='hg-functionBtn'] {
              height: 60px;
              margin: 0px 50px;
              flex-basis: 76%;
            }
          }

          & [class*='hg-functionBtn'] {
            color: var(--color-primary);
            box-shadow: 1px 1px var(--color-primary-opaque);
          }
        }
      }
    }
    min-width: 640px;
    background: none;
    flex-basis: 80%;
  }
}

.numPad {
  .keyboard {
    &Numpad {
      &[class*='simple-keyboard'] {
        & [class*='hg-rows'] {
          height: 280px;
          display: flex;
          flex-direction: column;

          & [class*='hg-row'] {
            width: 100%;
            flex-grow: 1;
          }
        }
        & [class*='hg-button'] {
          &[class*='hg-button-numpad0'] {
            flex-basis: 60%;
          }
          &[class*='hg-functionBtn'] {
            &[class*='hg-button-numpadenter'] {
              & span {
                transform: rotate(90deg);
                white-space: nowrap;
              }
              color: var(--color-primary);
              box-shadow: 1px 1px var(--color-primary-opaque);
            }
          }
          width: 50px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        background: none;
        // width: 160px;
      }
    }
  }

  &End {
    & [class*='hg-rows'] {
      & [class*='hg-row'] {
        &:nth-child(2) {
          flex-basis: 100%;
        }

        & [class*='hg-button-empty'] {
          color: #fff;
          background-color: var(--color-warning);
          span {
            font-size: 24px;
            margin-top: -7px;
          }
        }

        & [class*='hg-button-close'] {
          color: #fff;
          background-color: var(--color-success);
        }
      }
    }

    max-width: 70px;
    flex-basis: 4%;
  }
  display: flex;
  flex-basis: 16%;
  align-items: flex-end;
}

.touch-keyboard {
  & [class*='registrati_formContainer'] {
    padding-bottom: 350px;
  }
}
