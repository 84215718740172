:root {
  // Color
  // ------------------
  // Primary
  --color-primary: #e96105;
  --color-primary-opaque: rgba(233, 97, 5, 0.4);
  --color-primary-hover: #b14f0c;
  --color-primary-press: #8a3800;
  --color-secondary: #4b565c;
  --color-neutral-01: #ffffff;
  --color-neutral-02: #0f1315;
  // Divider
  --color-divider-primary: rgba(75, 86, 92, 0.4);
  --color-divider-secondary: rgba(75, 86, 92, 0.2);
  // Bingo
  --color-cat-01: #ffd848;
  --color-cat-02: #d599e8;
  --color-cat-03: #fe7c7c;
  --color-cat-04: #ff729c;
  --color-cat-05: #cbecf8;
  // Lottery
  --color-cat-06: #004698;
  --color-cat-07: #ffd511;
  --color-cat-08: #168738;
  --color-cat-09: #d5f1b7;
  --color-cat-10: #ff6f00;
  --color-cat-23: #0265c3;
  --color-cat-11: #62c5db;
  --color-cat-12: #0090a9;
  --color-cat-13: #ffaf00;
  --color-cat-14: #f58220;
  // Poker
  --color-cat-15: #066621;
  --color-cat-16: #760909;
  --color-cat-17: #d8cbac;
  --color-cat-18: #461189;
  --color-cat-19: #1c2f94;
  --color-cat-20: #070707;
  --color-cat-21: #b20606;
  --color-cat-22: #b0b0b0;
  // Ligth mode
  --color-bg: #e7ecef;
  --color-surface-01: #fcfcfc;
  --color-surface-02: #f2f3f3;
  --color-surface-03: #e0e3e3;
  --color-accent: #0057ff;
  --color-accent-opaque: rgba(0, 87, 255, 0.4);
  --color-on-bg-primary: #0f1315;
  --color-on-bg-secondary: rgba(75, 86, 92, 0.9);
  --color-divider-primary: rgba(75, 86, 92, 0.1);
  --color-success: #0c7923;
  --color-warning: #d61818;
  --color-shadow: rgba(0, 0, 0, 0.16);
  --color-drop-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.04);
  --color-open: #4daddd;
  // Font
  // ------------------
  --font-family-sans: 'korolev', sans-serif;
  --font-family-roboto: 'Roboto', sans-serif;
  --font-style-italic: italic;
  // Font Weight
  // ------------------
  --text-weight-light: 300;
  --text-weight-medium: 500;
  --text-weight-bold: 700;
  // Spacing
  // ------------------
  --space-unit: 0.8rem;
  --space-size-0-5: calc(0.5 * var(--space-unit)); // 4px
  --space-size-1: calc(1 * var(--space-unit)); // 8px
  --space-size-1-5: calc(1.5 * var(--space-unit)); // 12px
  --space-size-2: calc(2 * var(--space-unit)); // 16px
  --space-size-3: calc(3 * var(--space-unit)); // 24px
  --space-size-4: calc(4 * var(--space-unit)); // 32px
  --space-size-5: calc(5 * var(--space-unit)); // 40px
  --space-size-6: calc(6 * var(--space-unit)); // 48px
  --space-size-7: calc(7 * var(--space-unit)); // 56px
  --space-size-8: calc(8 * var(--space-unit)); //64px
  --space-size-10: calc(10 * var(--space-unit)); // 80px
  --space-size-12: calc(12 * var(--space-unit)); // 96px
  --max-width-body: 192rem;
  --pad-container-xs: 2rem;
  --pad-container-sm: var(--space-size-4);
  --pad-container-lg: var(--space-size-5);
  --divider-primary: 1px solid var(--color-divider-primary);
  --arrow-carousel-prev: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.6 6L6.6 -6.11959e-08L8 1.4L3.4 6L8 10.6L6.6 12L0.6 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
  --arrow-carousel-next: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.4 6L1.4 12L-6.1196e-08 10.6L4.6 6L-4.63341e-07 1.4L1.4 -6.11959e-08L7.4 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
}

[data-theme='dark'] {
  --color-bg: #0f1315;
  --color-surface-01: #1b2022;
  --color-surface-02: #252b2e;
  --color-surface-03: #30373b;
  --color-accent: #ebff00;
  --color-accent-opaque: rgba(235, 255, 0, 0.4);
  --color-on-bg-primary: #ffffff;
  --color-on-bg-secondary: rgba(255, 255, 255, 0.6);
  --color-divider-primary: rgba(75, 86, 92, 0.3);
  --color-success: #55d470;
  --color-warning: #f05d5d;
  --color-drop-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.16);
}

[data-theme='happybet-dark'] {
  --color-bg: #031222;
  --color-surface-01: #091f35;
  --color-surface-02: #112f4f;
  --color-surface-03: #123b63;
  --color-accent: #509dd4;
  --color-accent-opaque: rgba(80, 157, 212, 0.4);
  --color-on-bg-primary: #ffffff;
  --color-on-bg-secondary: rgba(255, 255, 255, 0.6);
  --color-divider-primary: rgba(51, 90, 131, 0.3);
  --color-success: #55d470;
  --color-warning: #ff5e5e;
  --color-drop-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.16);
}

[data-theme='happybet-light'] {
  --color-bg: #e7ecef;
  --color-surface-01: #fcfcfc;
  --color-surface-02: #eef2f3;
  --color-surface-03: #dae1e3;
  --color-accent: #b13b10;
  --color-accent-opaque: rgba(177, 59, 16, 0.4);
  --color-on-bg-primary: #112f4f;
  --color-on-bg-secondary: rgba(51, 90, 131, 0.9);
  --color-divider-primary: rgba(51, 90, 131, 0.3);
  --color-success: #0c7923;
  --color-warning: #d61818;
  --color-drop-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.16);
}
