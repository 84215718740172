// SMACSS Base Rules
// ----------------------------
// Base styles include setting heading sizes, default link styles, default
// font styles, and body backgrounds.
// There should be no need to use !important in a Base style.
// ==========================================================================

// Set box-sizing globally to handle padding and border widths
html {
  &[data-loading='true'] {
    a,
    *[class*='Tooltip'] {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
    @media screen and (min-width: $breakpoint-lg) {
      display: block;
    }

    @media screen and (min-width: $breakpoint-lg) {
      overflow-y: auto;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

body,
div,
a,
p,
th,
td,
ul,
li,
blockquote,
figcaption,
cite,
i,
em,
b,
strong,
button,
input,
select {
  font-family: var(--font-family-sans);
}

// Default body styles
body {
  position: relative;
  height: 100%;
  margin: 0 auto;
  font-family: var(--font-family-sans);
  @include body-medium;
  //letter-spacing: var(--text-letter-spacing);
  // > don't use font-smoothing for light text on dark background!!
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: var(--color-bg);

  /* Hide scrollbar for IE, Edge and Firefox */
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  @media screen and (min-width: $breakpoint-lg) {
    letter-spacing: 0.06em;

    &::-webkit-scrollbar {
      width: 1rem;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-surface-02);
      border-radius: 20px;
      border-right: 0.2rem solid var(--color-bg);
      border-left: 0.2rem solid var(--color-bg);
    }
  }
}

.bodyOs {
  // for safari ios
  padding-bottom: env(safe-area-inset-bottom);
  height: -webkit-fill-available;
  overflow-x: clip;
}

.italic,
em,
i {
  font-style: var(--font-style-italic);
}

b,
strong {
  font-weight: var(--text-weight-bold);
}

figure {
  margin: 0;
  padding: 0;
}

img {
  // height: auto;
  // width: 100%;
  max-width: 100%;
}

.no-scroll {
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

// since we are using sticky stiles (depending on parent scroll)
// overflow is set scroll + auto, on body element only scrollbars are set to hidden (overflow is left in place)
// scommesse menu is open, scroll is lock (with no-scroll sclass), elements alignet to right need to increase the right margin position

html.no-scroll,
html.no-scroll [class^='CarrelloTrigger_container_'] {
  margin-right: var(--scrollbar-width);
}

// used in tabs with accordion from seo HTML content
.linkSnai2020 {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}
